<div class="dialog">
  <div class="dialog-content">
    <div class="header">
      <p>Manage Columns</p>
      <button (click)="resetToDefault()">Reset</button>
    </div>
    <div class="search-container">
      <input
        type="text"
        placeholder="Search Columns"
        [(ngModel)]="searchText"
      />
      <mat-icon>search</mat-icon>
    </div>
    <div class="column-list">
      <div
        class="column"
        *ngFor="let column of data.columns | columnSearch : searchText"
      >
        <div>
          <input
            type="checkbox"
            [(ngModel)]="column.visible"
            (change)="columnVisibilityChanged(column)"
          />
          <label>{{ column.label }}</label>
        </div>

        <div
          *ngIf="column.hasOwnProperty('timestamp')"
          class="timestamp-option"
        >
          <label class="checkbox-container">
            <input
              type="checkbox"
              [(ngModel)]="column.timestamp"
              (change)="columnVisibilityChanged(column)"
            />
            <span>Show Timestamp</span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="footer">
    <button (click)="onNoClick()">Close</button>
  </div>
</div>
