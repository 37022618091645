import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

enum dateFormat {
  mediumDate = "MM/dd/yyyy HH:mm:ss",
  medium = "MM/dd/yy, h:mm:ss a",
  shortenedTimestamp = "MM/dd/yyyy HH:mm",
  full = `EEEE, MMMM d, y 'at' h:mm:ss a zzzz`,
}
const datePipe = new DatePipe("en-US");
@Pipe({
  name: "dateFormat",
})
export class DateFormatPipe implements PipeTransform {
  transform(value: string, formatType: string): string {
    let format = "MM/dd/yy";

    switch (formatType) {
      case "mediumDate":
        format = dateFormat.mediumDate;
        break;
      case "medium":
        format = dateFormat.medium;
        break;
      case "shortenedTimestamp":
        format = dateFormat.shortenedTimestamp;
        break;
      case "full":
        format = dateFormat.full;
        break;

      default:
        format;
        break;
    }

    return datePipe.transform(value, format);
  }
}
